<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
export default {
  page: {
    title: "Permissions.",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Permissions",
      stdPermsHeaderLabels: [
        {
          id: "create",
          text: "Création",
        },

        {
          id: "update",
          text: "Modification",
        },

        {
          id: "read",
          text: "Lecture",
        },

        {
          id: "delete",
          text: "Suppression",
        },

        {
          id: "list",
          text: "Liste",
        },
      ],
      stdPermissions: [],
      operationsPermissions: [],
      dashboardPermissions: [],
      techPermissions: [],
      rolePermissions : [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      currentRoleData: {
          uuid: "",
          name: ""
      },
      items: [
        {
          text: "Paramètres",
          to: {name: 'base.settings.index'},
        },
        {
          text: "Rôles",
          to: {name: 'settings.roles.index'},
        },
        {
          text: "Permissions",
          active: true,
        },
      ],
    };
  },

  mounted() {
      this.fetchRoleData();
    this.fetchAllPermissionsTable();
  },

  methods: {
    fetchAllPermissionsTable() {
      
      this.$http
        .post("/system/roles/fetchAllPermissionsTable")

        .then((res) => {
          this.stdPermissions = res.data.permissions.std;
          this.techPermissions = res.data.permissions.techPermissions;
          this.operationsPermissions = res.data.permissions.operationsPermissions;
          this.dashboardPermissions = res.data.permissions.dashboardPermissions;
        })
        .catch((error) => {
          this.$toast.error(" fetchAllPermissionsTable "+error.message);
        })
        .finally(() => {});
    },

    fetchRoleData() {
        var uuid = this.$route.params.uid;
        this.$http
        .post("/system/roles/getSingleRolePermissions", {
            uuid: uuid
        })
        .then((res) => {
          var status = res.data.status;
          var permissions = res.data.permissions;
          switch (status) {
            case 200:
                    this.rolePermissions = permissions;
              break;

            case 404:
                this.$toast.warning(res.data.original.msg);
              break;

            case 500:
                this.$toast.error(res.data.original.msg);
              break;
          }
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    roleHasPermission(permission){
        return this.rolePermissions.includes(permission);
    },

    roleHasAllPermissions(permissions){
      var hasAllPermissions = true;
      permissions.forEach(modulePermissions => {
        // console.log(modulePermissions);
        modulePermissions.permissions.forEach(singlePermission => {
          if(!this.rolePermissions.includes(singlePermission)){
            hasAllPermissions = false;
          }
        });
      });
      return hasAllPermissions;
    },  

    togglePermissions(permissions){
      this.batchUpdateRolePermission(permissions);
    },

    batchUpdateRolePermission(permissions){
      var uuid = this.$route.params.uid;
      let loader = this.$loading.show({
              color: '#000000',
              loader: 'spinner',
              width: 64,
              height: 64,
              backgroundColor: '#ffffff',
              opacity: 0.5,
              zIndex: 999,
          })
        this.$http
        .post("/system/roles/batchUpdateRolePermission", {
            uuid: uuid,
            permissions: permissions
        })
        .then((res) => {   
            var status = res.data.original.status;
          switch (status) {
            case 200:
              this.fetchRoleData();
              this.$toast.success(res.data.original.msg, "", {position: 'bottomLeft'});
              break;

            case 500:
              this.$toast.warning(res.data.original.msg, "", {position: 'bottomLeft'});
              break;
          } 
          loader.hide();      
        })
        .catch(() => {
          loader.hide();
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },

    updateRolePermission(perm){
       var uuid = this.$route.params.uid;
       var loader = this.$loading.show();
        this.$http
        .post("/system/roles/updateRolePermission", {
            uuid: uuid,
            perm: perm
        })
        .then((res) => {  
          loader.hide(); 
            var status = res.data.original.status;
          switch (status) {
            case 200:
              // this.$toast.success(res.data.original.msg);
              this.$toast.success(res.data.original.msg, "", {position: 'bottomLeft'});
              break;

            case 500:
              this.$toast.warning(res.data.original.msg, "", {position: 'bottomLeft'});
              break;
          }       
        })
        .catch(() => {
          loader.hide(); 
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide(); 
          //Perform action in always
        });
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      var router = this.$router;
      this.$http
        .post("/system/roles/store", this.roleForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "settings.roles.index" });
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"></h4>

            <h4>1- Permissions de base <span class="float-right">{{$route.params.name}}</span></h4>
            <br />
            <div class="table-responsive">
              <div
                v-for="(stdPermission, index) in stdPermissions"
                v-bind:key="index"
              > <br>
                <div
                  role="alert"
                  style="width: 100%"
                  aria-live="polite"
                  aria-atomic="true"
                  class="alert alert-info"
                >
                  <h6 style="display:inline-block"><b>{{ stdPermission.module }}</b></h6>
                  <b-form-checkbox :checked="roleHasAllPermissions(stdPermission.permissions)" @change="togglePermissions(stdPermission.permissions)" style="display:inline-block; float: right;" switch class="mb-1"/>
                </div> <br>
                <table class="table mb-0">
                    <thead>
                  <tr>
                    <th style="width: 20%">Module</th>
                    <th
                    style="width: 5%"
                      v-for="stdPermsHeaderLabel in stdPermsHeaderLabels"
                      v-bind:key="stdPermsHeaderLabel.id"
                    >
                      {{ stdPermsHeaderLabel.text }}
                    </th>
                  </tr>
                </thead>
                  <tbody>
                    <tr
                      v-for="(stdPermissionItem, index) in stdPermission.permissions"
                      v-bind:key="index"
                    >
                      <td><b>{{ stdPermissionItem.section }}</b></td>
                      <td
                        v-for="(permission, index) in stdPermissionItem.permissions"
                        v-bind:key="index"
                      >
                      <b-form-checkbox :checked="roleHasPermission(permission)" @change="updateRolePermission(permission)" :id="permission" :name="permission" switch class="mb-1">
                            <label></label>
                        </b-form-checkbox>
                                           
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <h4>2- Permissions Techniques</h4>
            <br />

            <div class="row" v-if="techPermissions[0]">
              <div class="col-md-3"  v-for="(techPermission, index) in techPermissions[0].permissions" v-bind:key="index">
                <b-form-checkbox :checked="roleHasPermission(index)" @change="updateRolePermission(index)" :id="index" :name="index" switch class="mb-1">
                    <label>{{ techPermission }}</label>
                </b-form-checkbox>
              </div>
            </div>
            <br />
            <h4>3- Permissions des opérations</h4>
            <br />

            <div class="row" v-if="operationsPermissions">
              <template v-for="opPermissions in operationsPermissions">  
                <div class="col-md-3" v-for="(techPermission, index) in opPermissions.permissions" v-bind:key="index">
                  <b-form-checkbox :checked="roleHasPermission(index)" @change="updateRolePermission(index)" :id="index" :name="index" switch class="mb-1">
                      <label>{{ techPermission }}</label>
                  </b-form-checkbox>
                </div>
              </template>
            </div>
            <br />
            <h4>4- Tableaux de bords</h4>
            <br />

            <div class="row" v-if="dashboardPermissions[0]">
              <div class="col-md-3" v-for="(techPermission, index) in dashboardPermissions[0].permissions" v-bind:key="index">
                <b-form-checkbox :checked="roleHasPermission(index)" @change="updateRolePermission(index)" :id="index" :name="index" switch class="mb-1">
                    <label>{{ techPermission }}</label>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
