var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title" }),
              _c("h4", [
                _vm._v("1- Permissions de base "),
                _c("span", { staticClass: "float-right" }, [
                  _vm._v(_vm._s(_vm.$route.params.name))
                ])
              ]),
              _c("br"),
              _c(
                "div",
                { staticClass: "table-responsive" },
                _vm._l(_vm.stdPermissions, function(stdPermission, index) {
                  return _c("div", { key: index }, [
                    _c("br"),
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-info",
                        staticStyle: { width: "100%" },
                        attrs: {
                          role: "alert",
                          "aria-live": "polite",
                          "aria-atomic": "true"
                        }
                      },
                      [
                        _c("h6", { staticStyle: { display: "inline-block" } }, [
                          _c("b", [_vm._v(_vm._s(stdPermission.module))])
                        ]),
                        _c("b-form-checkbox", {
                          staticClass: "mb-1",
                          staticStyle: {
                            display: "inline-block",
                            float: "right"
                          },
                          attrs: {
                            checked: _vm.roleHasAllPermissions(
                              stdPermission.permissions
                            ),
                            switch: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.togglePermissions(
                                stdPermission.permissions
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("table", { staticClass: "table mb-0" }, [
                      _c("thead", [
                        _c(
                          "tr",
                          [
                            _c("th", { staticStyle: { width: "20%" } }, [
                              _vm._v("Module")
                            ]),
                            _vm._l(_vm.stdPermsHeaderLabels, function(
                              stdPermsHeaderLabel
                            ) {
                              return _c(
                                "th",
                                {
                                  key: stdPermsHeaderLabel.id,
                                  staticStyle: { width: "5%" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(stdPermsHeaderLabel.text) + " "
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ]),
                      _c(
                        "tbody",
                        _vm._l(stdPermission.permissions, function(
                          stdPermissionItem,
                          index
                        ) {
                          return _c(
                            "tr",
                            { key: index },
                            [
                              _c("td", [
                                _c("b", [
                                  _vm._v(_vm._s(stdPermissionItem.section))
                                ])
                              ]),
                              _vm._l(stdPermissionItem.permissions, function(
                                permission,
                                index
                              ) {
                                return _c(
                                  "td",
                                  { key: index },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "mb-1",
                                        attrs: {
                                          checked: _vm.roleHasPermission(
                                            permission
                                          ),
                                          id: permission,
                                          name: permission,
                                          switch: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.updateRolePermission(
                                              permission
                                            )
                                          }
                                        }
                                      },
                                      [_c("label")]
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        }),
                        0
                      )
                    ])
                  ])
                }),
                0
              ),
              _c("h4", [_vm._v("2- Permissions Techniques")]),
              _c("br"),
              _vm.techPermissions[0]
                ? _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.techPermissions[0].permissions, function(
                      techPermission,
                      index
                    ) {
                      return _c(
                        "div",
                        { key: index, staticClass: "col-md-3" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mb-1",
                              attrs: {
                                checked: _vm.roleHasPermission(index),
                                id: index,
                                name: index,
                                switch: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.updateRolePermission(index)
                                }
                              }
                            },
                            [_c("label", [_vm._v(_vm._s(techPermission))])]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c("br"),
              _c("h4", [_vm._v("3- Permissions des opérations")]),
              _c("br"),
              _vm.operationsPermissions
                ? _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _vm._l(_vm.operationsPermissions, function(
                        opPermissions
                      ) {
                        return _vm._l(opPermissions.permissions, function(
                          techPermission,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "col-md-3" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "mb-1",
                                  attrs: {
                                    checked: _vm.roleHasPermission(index),
                                    id: index,
                                    name: index,
                                    switch: ""
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.updateRolePermission(index)
                                    }
                                  }
                                },
                                [_c("label", [_vm._v(_vm._s(techPermission))])]
                              )
                            ],
                            1
                          )
                        })
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _c("br"),
              _c("h4", [_vm._v("4- Tableaux de bords")]),
              _c("br"),
              _vm.dashboardPermissions[0]
                ? _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.dashboardPermissions[0].permissions, function(
                      techPermission,
                      index
                    ) {
                      return _c(
                        "div",
                        { key: index, staticClass: "col-md-3" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mb-1",
                              attrs: {
                                checked: _vm.roleHasPermission(index),
                                id: index,
                                name: index,
                                switch: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.updateRolePermission(index)
                                }
                              }
                            },
                            [_c("label", [_vm._v(_vm._s(techPermission))])]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }